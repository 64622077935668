import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Controller, type Control, type FieldErrors } from 'react-hook-form';
import { type ManualAction } from '../../../../../../hooks/useManualActions';
import { ManualActionInput } from './ManualActionsInput';

type ManualActionsNonResolvedFieldsProps = {
  fields: ManualAction['stepTemplate']['fields'];
  control: Control;
  formErrors: FieldErrors;
  disabled?: boolean;
};

const useManualActionsNonResolvedFieldsStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export const ManualActionsNonResolvedFields = (
  props: ManualActionsNonResolvedFieldsProps,
) => {
  const { fields, control, formErrors, disabled } = props;
  const classes = useManualActionsNonResolvedFieldsStyles();

  return (
    <div className={classes.root}>
      {fields.map(fieldPropperty => (
        <Controller
          key={fieldPropperty.key}
          name={fieldPropperty.key}
          control={control}
          render={({ field }) => (
            <ManualActionInput
              field={field}
              fieldProperty={fieldPropperty}
              disabled={!!disabled}
              errorText={formErrors[fieldPropperty.key]?.message?.toString()}
            />
          )}
        />
      ))}
    </div>
  );
};
