import BugReportIcon from '@material-ui/icons/BugReport';
import React from 'react';
import { SubscriptionEntity } from '../../../../../../../../types/SubscriptionEntity';
import { BuildIcon, OpenInNewIcon } from 'backstage-plugin-icons-react';
import { useNavigate } from 'react-router';
import { useEntityRoute } from '../../../../../../../../hooks/useEntityRoute';
import { ManualAction } from '../../../../../../hooks/useManualActions';
import { MoreActionsVert } from '@internal/backstage-plugin-adeo-core-components-react';

export type DeploymentAccordionActionsProps = {
  subscription: SubscriptionEntity;
  nonResolvedManualActions?: ManualAction[];
  resolvedManualActions?: ManualAction[];
  onInspectEntity: () => void;
  onResolveManualActions: () => void;
};

export const DeploymentAccordionActions = (
  props: DeploymentAccordionActionsProps,
) => {
  const {
    subscription,
    onInspectEntity,
    nonResolvedManualActions,
    resolvedManualActions,
    onResolveManualActions,
  } = props;

  const navigate = useNavigate();
  const subscriptionRoute = useEntityRoute(subscription);

  const handleResolveManualActions = (event: React.MouseEvent<HTMLElement>) => {
    onResolveManualActions();
    event.stopPropagation();
    event.preventDefault();
  };

  const handleInspectEntity = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onInspectEntity();
  };

  const goToSubscription = (event: React.MouseEvent<HTMLElement>) => {
    if (subscriptionRoute) {
      navigate(subscriptionRoute);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const resolvedMenuItem =
    resolvedManualActions && resolvedManualActions.length > 0
      ? {
          Icon: BuildIcon,
          text: 'Manage manual actions',
          action: handleResolveManualActions,
        }
      : {
          Icon: BuildIcon,
          text: 'Manage manual actions',
        };

  return (
    <MoreActionsVert
      items={[
        nonResolvedManualActions && nonResolvedManualActions.length > 0
          ? {
              Icon: BuildIcon,
              text: 'Resolve manual actions',
              action: handleResolveManualActions,
            }
          : resolvedMenuItem,
        {
          Icon: BugReportIcon,
          text: 'Inspect subscription entity',
          action: handleInspectEntity,
        },
        {
          Icon: OpenInNewIcon,
          text: 'Go to subscription',
          action: goToSubscription,
        },
      ]}
    />
  );
};
