// This component is an override from : https://github.com/backstage/backstage/blob/ca9bb31b6f0462b9b338aca42ce0105cb974d465/packages/core-components/src/layout/SignInPage/SignInPage.tsx
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { SignInPageProps, useApi } from '@backstage/core-plugin-api';
import React, { useLayoutEffect, useState } from 'react';
import {
  Content,
  Page,
  Progress,
  SignInProviderConfig,
  UserIdentity,
} from '@backstage/core-components';
import { useSearchParams } from 'react-router-dom';
import { useMountEffect } from '@react-hookz/web';
import packageFactory from './package-factory.png';
import aapLogo from './aap-logo.png';
import { useSnackbarStore } from '../snackbar/stores/use-snackbar-store';

const useStyles = makeStyles(
  theme => ({
    content: {
      height: '100vh',
    },
    containerGrid: { height: '100%' },
    imageGrid: { height: '100%', position: 'relative' },
    packageFactory: { height: '100%' },
    aapLogo: {
      position: 'absolute',
      left: theme.typography.pxToRem(35),
      top: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(55),
    },
    loginGrid: { alignContent: 'center' },
    container: {
      padding: 0,
      listStyle: 'none',
    },
    loginButton: {
      marginTop: theme.spacing(5),
    },
    figure: {
      height: '100%',
      margin: 0,
      padding: 0,
    },
    artistLink: {
      textDecoration: 'underline',
    },
    figcaption: {
      fontSize: '.75rem',
    },
  }),
  { name: 'BackstageSignInPage' },
);

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
};

export const SignInPage = ({
  provider,
  onSignInSuccess,
}: SingleSignInPageProps) => {
  const classes = useStyles();
  const authApi = useApi(provider.apiRef);
  const addSnackbar = useSnackbarStore(state => state.addSnackbar);

  const [loading, setLoading] = useState(true);

  // User was redirected back to sign in page with error from auth redirect flow
  const [searchParams, _setSearchParams] = useSearchParams();
  const errorParam = searchParams.get('error');

  const handleLogin = async () => {
    try {
      const identityResponse = await authApi.getBackstageIdentity({
        instantPopup: true,
      });
      if (!identityResponse) {
        throw new Error(
          `The ${provider.title} provider is not configured to support sign-in`,
        );
      }

      const profile = await authApi.getProfile();

      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          profile,
          authApi,
        }),
      );
    } catch (error) {
      // User closed the sign-in modal
      addSnackbar(<span>{(error as Error).message}</span>, 'error');
    }
  };

  // Check if user is already logged-in
  // adapted from https://github.com/backstage/backstage/blob/e89242027d65bfd712908e88ee360bf10b29effe/packages/core-components/src/layout/SignInPage/providers.tsx#L127
  useLayoutEffect(() => {
    if (!loading) {
      return undefined;
    }

    let didCancel = false;

    const silentLogin = async () => {
      const identityResponse = await authApi.getBackstageIdentity({
        optional: true,
      });

      if (!identityResponse) {
        return undefined;
      }

      const profile = await authApi.getProfile();

      return UserIdentity.create({
        identity: identityResponse.identity,
        profile,
        authApi,
      });
    };

    silentLogin()
      .then(result => {
        if (didCancel) {
          return;
        }
        if (result) {
          onSignInSuccess(result);
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
        if (didCancel) {
          return;
        }
        addSnackbar(<span>{(error as Error).message}</span>, 'error');
        setLoading(false);
      });

    return () => {
      didCancel = true;
    };
  }, [loading, onSignInSuccess, addSnackbar, authApi]);

  useMountEffect(() => {
    if (errorParam) {
      addSnackbar(<div>{errorParam}</div>, 'error');
    }
  });

  return loading ? (
    <Progress />
  ) : (
    <Page themeId="home">
      <Content className={classes.content}>
        <Grid container className={classes.containerGrid}>
          <Grid xs={6} className={classes.imageGrid} item>
            <figure className={classes.figure}>
              <img
                src={packageFactory}
                alt="Factory settings"
                className={classes.packageFactory}
              />
              <figcaption className={classes.figcaption}>
                <span className="caption">Factory Settings. </span>
                <i className="photo-credit">
                  Designed by{' '}
                  <a
                    href="https://dribbble.com/painterr"
                    className={classes.artistLink}
                  >
                    Dzmitry Kazak
                  </a>
                </i>
              </figcaption>
            </figure>

            <img src={aapLogo} alt="AAP logo" className={classes.aapLogo} />
          </Grid>
          <Grid xs={6} item className={classes.loginGrid}>
            <Box>
              <Typography variant="h5">Sign In</Typography>
              <Typography variant="h2">Adeo Application Platform</Typography>
              <Typography variant="body2">
                Discover valuable insights within Adeo's <b>packages</b> and{' '}
                <b>products</b>.
              </Typography>
              <Typography variant="body2">
                Keep track of your <b>deployments</b> and <b>subscriptions</b>{' '}
                effortlessly.
              </Typography>
              <Button
                color="primary"
                variant="contained"
                className={classes.loginButton}
                onClick={() => handleLogin()}
              >
                Sign in with Ping ID
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
