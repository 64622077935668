import React, { useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useProductVersionAndSubscription } from './useProductVersionAndSubscription';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { NameField } from './fields/NameField';
import { ProductVersionField } from './fields/ProductVersionField';
import { OwnerField } from './fields/OwnerField';
import { DeployProductDialogTitle } from './DeployProductDialogTitle';
import { PACKAGE_VERSION_ID_ANNOTATION } from 'custom-annotations';
import { DeployIcon } from 'backstage-plugin-icons-react';
import { DeployProductDialogActions } from './DeployProductDialogActions';
import { DeployProductDialogFormSection } from './DeployProductDialogFormSection';
import { useNavigate } from 'react-router';
import { useUpsertSubscriptionMutation } from './useUpsertSubscriptionMutation';
import { useSnackbarStore } from '../../../../../../../snackbar/stores/use-snackbar-store';
import { useEntityRoute } from '../../../../../../../../hooks/useEntityRoute';

interface DeployProductDialogProps {
  open: boolean;
  onClose: () => void;
}

const SUBSCRIPTION_REDIRECT_DELAY = 2000;

const useDeployProductDialogStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  dialogContent: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
  },
  alreadyDeployedMessage: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  alreadyDeployedVersion: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const schema = z.object({
  subscriptionName: z
    .string({ required_error: 'The deployment name must not be empty' })
    .regex(
      /^[a-zA-Z0-9][a-zA-Z0-9_\-\s]{1,31}[a-zA-Z0-9]$/,
      'The deployment name must be between 3 and 33 characters long and can only contain letters, numbers, hyphens, underscores and spaces. It must start and end with a letter or number.',
    ),
  stringifiedVersion: z.string({
    required_error: 'A version must be selected',
  }),
  ownerRef: z.string({ required_error: 'Owner must be selected' }),
});

export type DeployProductFormInputs = z.infer<typeof schema>;

export const DeployProductDialog = ({
  open,
  onClose,
}: DeployProductDialogProps) => {
  const classes = useDeployProductDialogStyles();
  const navigate = useNavigate();
  const addSnackbar = useSnackbarStore(state => state.addSnackbar);

  const form = useForm<DeployProductFormInputs>({
    defaultValues: {
      subscriptionName: '',
      stringifiedVersion: '',
      ownerRef: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(schema),
  });

  const { entity: product } = useEntity();
  const productRoute = useEntityRoute(product);
  const productVersionsAndSubscription =
    useProductVersionAndSubscription(product);

  const existingDeploymentOnDev = productVersionsAndSubscription?.find(
    ({ subscription }) => !!subscription,
  );

  const stringifiedSelectedVersion = form.watch('stringifiedVersion');
  const hasSelectedDeployedVersion = useMemo(() => {
    const selectedVersionRef = stringifiedSelectedVersion
      ? stringifyEntityRef(JSON.parse(stringifiedSelectedVersion))
      : '';

    return (
      existingDeploymentOnDev?.productVersion &&
      stringifyEntityRef(existingDeploymentOnDev.productVersion) ===
        selectedVersionRef
    );
  }, [existingDeploymentOnDev, stringifiedSelectedVersion]);

  // Fill form with existing subscription in case of an update
  useEffect(() => {
    if (existingDeploymentOnDev) {
      if (existingDeploymentOnDev.productVersion) {
        form.setValue(
          'stringifiedVersion',
          JSON.stringify(existingDeploymentOnDev.productVersion),
        );
      }

      if (existingDeploymentOnDev.subscription) {
        form.setValue(
          'subscriptionName',
          existingDeploymentOnDev.subscription.metadata.title as string,
        );
        form.setValue(
          'ownerRef',
          existingDeploymentOnDev.subscription.spec.owner as string,
        );
      }
    } else {
      form.reset({
        subscriptionName: '',
        stringifiedVersion: '',
        ownerRef: '',
      });
    }
  }, [
    existingDeploymentOnDev,
    form,
    open, // not in used effect but needs to trigger it
  ]);

  const upsertSubscriptionMutation = useUpsertSubscriptionMutation({
    httpMethod: existingDeploymentOnDev ? 'PUT' : 'POST',
    onSuccess: _data => {
      addSnackbar(
        <div>
          Deployment successfully{' '}
          {existingDeploymentOnDev ? 'updated' : 'created'}
        </div>,
        'success',
      );
      setTimeout(() => {
        navigate(`${productRoute}/deployments`);
      }, SUBSCRIPTION_REDIRECT_DELAY);
    },
    onError: mutateError => {
      addSnackbar(
        <div>Something went wrong. {mutateError.message.toString()}</div>,
        'error',
      );
    },
  });

  const onSubmit: SubmitHandler<DeployProductFormInputs> = async data => {
    const { subscriptionName, stringifiedVersion, ownerRef } = data;

    const selectedProductVersion = JSON.parse(stringifiedVersion) as Entity;

    const body = existingDeploymentOnDev
      ? {
          subscriptionId: existingDeploymentOnDev.subscription?.metadata.name,
          packageVersionId:
            selectedProductVersion.metadata.annotations?.[
              PACKAGE_VERSION_ID_ANNOTATION
            ],
          ownerId: ownerRef.split('/').pop(),
        }
      : {
          subscriptionName,
          packageVersionId:
            selectedProductVersion.metadata.annotations?.[
              PACKAGE_VERSION_ID_ANNOTATION
            ],
          ownerId: ownerRef.split('/').pop(),
        };

    upsertSubscriptionMutation.mutate(body);
  };

  const onCancel = () => {
    onClose();
    form.reset({
      subscriptionName: '',
      stringifiedVersion: '',
      ownerRef: '',
    });
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DeployProductDialogTitle
            id="customized-dialog-title"
            onClose={onCancel}
            title={
              existingDeploymentOnDev
                ? 'Update product deployment'
                : 'Deploy product'
            }
          />
          <DialogContent className={classes.dialogContent}>
            {existingDeploymentOnDev && (
              <Typography
                variant="body2"
                className={classes.alreadyDeployedMessage}
              >
                Product{' '}
                <span className={classes.bold}>
                  {product.metadata.title ?? product.metadata.name}
                </span>{' '}
                is already deployed in project
                <span className={classes.bold}> development</span> with the
                following product version:{' '}
                <span className={classes.alreadyDeployedVersion}>
                  {existingDeploymentOnDev.productVersion?.metadata.title ??
                    existingDeploymentOnDev.productVersion?.metadata.name}
                </span>
              </Typography>
            )}
            <DeployProductDialogFormSection
              index={1}
              title="Deployment information"
            >
              <NameField existingDeploymentOnDev={!!existingDeploymentOnDev} />
              <ProductVersionField
                productVersionsAndSubscription={productVersionsAndSubscription}
              />
            </DeployProductDialogFormSection>
            <Divider className={classes.divider} />
            <DeployProductDialogFormSection
              index={2}
              title="Select the deployment owner"
            >
              <OwnerField existingDeploymentOnDev={!!existingDeploymentOnDev} />
            </DeployProductDialogFormSection>
          </DialogContent>
          <DeployProductDialogActions
            onCancel={onCancel}
            disableSubmit={
              hasSelectedDeployedVersion || !form.formState.isValid
            }
            submitButtonIcon={
              upsertSubscriptionMutation.isPending ? (
                <CircularProgress />
              ) : (
                <DeployIcon />
              )
            }
            submitButtonText={
              existingDeploymentOnDev ? 'Update deployment' : 'Deploy'
            }
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
