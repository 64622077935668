import React, { useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi, fetchApiRef, configApiRef } from '@backstage/core-plugin-api';
import { type Entity } from '@backstage/catalog-model';
import { useSnackbarStore } from '../../snackbar/stores/use-snackbar-store';

const PRODUCT_MANUAL_ACTIONS = 'productManualActions';

export type ManualAction = {
  id: string;
  deploymentId: string;
  subscriptionId: string;
  productId: string;

  name: string;
  status: 'INITIALIZED' | 'SUCCESS';
  stepTemplateId: string;
  stepTemplate: {
    id: string;
    title: string;
    description: string;
    fields: {
      key: string;
      type: string;
    }[];
    createdAt: string;
    updatedAt: string;
  };

  createdAt: string;
  updatedAt: string;
};

export const useManualActions = () => {
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);

  const addSnackbar = useSnackbarStore(state => state.addSnackbar);

  const getProductManualActions = useCallback(
    async (productId: string) => {
      const response = await fetch(
        `${config.getString('backend.baseUrl')}/api/custom-endpoints/products/${productId}/steps`,
      );
      if (!response.ok) {
        addSnackbar(
          <>Failed to fetch manual actions for product with ID {productId}</>,
          'error',
        );
        return {
          error: `Failed to fetch manual actions for product with ID ${productId}`,
        };
      }
      return { data: await response.json() };
    },
    [fetch, config, addSnackbar],
  ) as (
    productId: string,
  ) => Promise<{ error?: string; data?: ManualAction[] }>;

  const putManualAction = useCallback(
    async (manualAction: ManualAction, data: Record<string, string>) => {
      const response = await fetch(
        `${config.getString('backend.baseUrl')}/api/custom-endpoints/steps/${manualAction.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            inputs: data,
          }),
        },
      );
      if (!response.ok) {
        addSnackbar(
          <>Failed to update manual action {manualAction.name}.</>,
          'error',
        );

        return {
          error: `Failed to update manual action ${manualAction.name}.`,
        };
      }
      return { data: await response.json() };
    },
    [fetch, config, addSnackbar],
  ) as (
    manualActionId: ManualAction,
    input: Record<string, string>,
  ) => Promise<{ error?: string; data?: ManualAction }>;

  return { getProductManualActions, putManualAction };
};

export const useGetProductManualActionsQuery = ({
  productId,
  enabled,
}: {
  productId: string;
  enabled: boolean;
}) => {
  const { getProductManualActions } = useManualActions();
  return useQuery({
    queryKey: [PRODUCT_MANUAL_ACTIONS, productId],
    queryFn: () => getProductManualActions(productId),
    enabled: enabled,
  });
};

export const usePutManualActionMutation = ({
  manualAction,
  entity,
}: {
  manualAction: ManualAction;
  entity: Entity;
}) => {
  const { putManualAction } = useManualActions();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ input }: { input: Record<string, string> }) =>
      putManualAction(manualAction, input),
    onSuccess: () => {
      if (entity?.spec?.type === 'product') {
        queryClient.invalidateQueries({
          queryKey: [PRODUCT_MANUAL_ACTIONS, entity.metadata.name],
        });
      }
    },
  });
};
