import {
  Entity,
  RELATION_DEPENDENCY_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useProductVersions } from '../../useProductVersions';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useEffect, useMemo, useState } from 'react';
import { SubscriptionEntity } from '../../../../../../../../types/SubscriptionEntity';

export type ProductVersionAndSubscription = {
  productVersion: Entity;
  subscription?: SubscriptionEntity;
};

/**
 * This hook is used to get the product versions of a product along with potential subscription to this version
 */
export const useProductVersionAndSubscription = (product: Entity) => {
  const catalogApi = useApi(catalogApiRef);

  const [productVersionsAndSubscription, setProductVersionsAndSubscription] =
    useState<ProductVersionAndSubscription[]>([]);

  const { entities: productVersions } = useProductVersions(product);

  const relatedDependencyOfRefs = useMemo(
    () =>
      productVersions?.flatMap(
        productVersion =>
          productVersion.relations
            ?.filter(relation => relation.type === RELATION_DEPENDENCY_OF)
            .map(relation => relation.targetRef) ?? [],
      ) ?? [],
    [productVersions],
  );

  useEffect(() => {
    catalogApi
      .getEntitiesByRefs({ entityRefs: relatedDependencyOfRefs })
      .then(({ items }) => {
        const subscriptions = items.filter(
          (item): item is SubscriptionEntity =>
            item?.spec?.type === 'subscription',
        );

        const versionsAndSubscription =
          productVersions?.map(productVersion => {
            const relatedSubscription = subscriptions.find(subscription =>
              productVersion.relations?.some(
                relation =>
                  relation.targetRef === stringifyEntityRef(subscription),
              ),
            );

            return {
              productVersion,
              subscription: relatedSubscription as
                | SubscriptionEntity
                | undefined,
            };
          }, []) ?? [];

        setProductVersionsAndSubscription(versionsAndSubscription);
      });
  }, [relatedDependencyOfRefs, catalogApi, productVersions]);

  return productVersionsAndSubscription;
};
