import { styled } from '@material-ui/core';
import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

const StyledStatusBadge = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: '.75rem',
  height: '.75rem',
  backgroundColor: theme.palette.info.main,
  content: ' ',
  display: 'inline-block',
  marginRight: theme.spacing(1),
  '&.danger': {
    backgroundColor: theme.palette.error.main,
  },
  '&.success': {
    backgroundColor: theme.palette.success.main,
  },
  '&.warning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.progress': {
    backgroundColor: theme.palette.info.main,
  },
  '&.unknown': {
    backgroundColor: theme.palette.grey['300'],
  },
}));

const dangerStatuses = [
  'SyncFailed',
  'Degraded',
  'Missing',
  'ManualActionRequired',
  'Stand by',
];
const successStatuses = ['Synced', 'Healthy'];
const warningStatuses = ['OutOfSync', 'Suspended'];
const progressStatuses = ['Syncing', 'Progressing'];

interface StatusBadgeProps extends HTMLAttributes<HTMLSpanElement> {
  status?: string;
}

export const StatusBadge = ({
  status,
  className,
  ...rest
}: StatusBadgeProps) => (
  <StyledStatusBadge
    className={clsx(
      className,
      dangerStatuses.includes(status ?? '') && 'danger',
      successStatuses.includes(status ?? '') && 'success',
      progressStatuses.includes(status ?? '') && 'progress',
      warningStatuses.includes(status ?? '') && 'warning',
      (!status || status === 'Unknown') && 'unknown',
    )}
    {...rest}
  />
);
