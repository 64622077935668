import React from 'react';
import { Entity, RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { Card, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';

import {
  renderCellIconText,
  Table,
  EntityPresentation,
  EntityPresentationHeader,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { useDelayedLoading } from '../../../../../../../hooks/useDelayedLoading';

type ProductVersionListItemProps = {
  entity: Entity;
  onOpenVersion: (entity: Entity) => void;
};

const useProductVersionsListItemStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  emptyContent: {
    display: 'flex',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  content: {
    padding: theme.spacing(2),
  },
}));

export const ProductVersionsListItem = (props: ProductVersionListItemProps) => {
  const { entity, onOpenVersion } = props;
  const classes = useProductVersionsListItemStyles();

  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_DEPENDS_ON,
  });

  const delayedLoading = useDelayedLoading(loading);

  const handleClick = () => {
    onOpenVersion(entity);
  };

  const actionButton = (
    <IconButton data-testid="action-button" size="medium" onClick={handleClick}>
      <ArrowForward />
    </IconButton>
  );

  return (
    <Card>
      <div className={classes.header}>
        <EntityPresentation size="small">
          <EntityPresentationHeader
            title={entity.metadata.title ?? ''}
            type="product version"
            actionButton={actionButton}
          />
        </EntityPresentation>
      </div>

      <div className={classes.emptyContent}>
        {error && <Alert severity="error">{error.message}</Alert>}
      </div>

      <div className={classes.content}>
        {delayedLoading ? (
          <Skeleton variant="rect" height={140} data-testid="skeleton" />
        ) : (
          <Table
            columns={[
              {
                field: 'type',
                headerName: 'Type',
                flex: 0.5,
                renderCell: renderCellIconText,
              },
              { field: 'title', headerName: 'Name', flex: 1 },
              { field: 'description', headerName: 'Description', flex: 2 },
            ]}
            rows={
              entities?.map(dependencies => ({
                title: dependencies.metadata.title,
                description: dependencies.metadata.description,
                type: dependencies.spec?.type?.toString(),
                id: dependencies.metadata.uid,
                raw: dependencies,
              })) ?? []
            }
            disableColumnSelector
            disableRowSelectionOnClick
          />
        )}
      </div>
    </Card>
  );
};

ProductVersionsListItem.displayName = 'ProductVersionsListItem';
