import { makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { Theme } from '@material-ui/core/styles';
import { useThemeMode } from '../../../../../../../../utils/useThemeMode';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';

export type TabContent<T = string[]> = Record<string, { data: T }>;

const useSectionContentListItemStyles = makeStyles<
  Theme,
  {
    showLineNumber: boolean;
    themeMode: string;
  }
>(theme => ({
  sectionContentListItem: {
    display: ({ showLineNumber }) =>
      showLineNumber ? 'contents' : 'list-item',
  },
  valueIndex: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2),
  },
  value: {
    color: ({ themeMode }) =>
      themeMode === 'dark'
        ? theme.palette.success.light
        : theme.palette.success.dark,
  },
}));

interface SectionContentListItemProps {
  showLineNumber: boolean;
  value: string;
  index: number;
  renderItemValue?: (value: string) => React.ReactNode;
}

export const SectionContentListItem = ({
  showLineNumber,
  value,
  index,
  renderItemValue,
}: SectionContentListItemProps) => {
  const themeMode = useThemeMode();
  const classes = useSectionContentListItemStyles({
    showLineNumber,
    themeMode,
  });

  return (
    <li key={value} className={classes.sectionContentListItem}>
      {showLineNumber && (
        <span className={classes.valueIndex}>{index + 1}</span>
      )}
      {renderItemValue ? (
        renderItemValue(value)
      ) : (
        <span className={classes.value}>{value}</span>
      )}
    </li>
  );
};

const useSectionContentListStyles = makeStyles<
  Theme,
  { showLineNumber: boolean }
>(() => ({
  sectionContentList: {
    display: ({ showLineNumber }) => (showLineNumber ? 'grid' : 'block'),
    gridTemplateColumns: 'auto 1fr',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
}));

interface SectionContentListProps {
  showLineNumber: boolean;
}

export const SectionContentList = ({
  children,
  showLineNumber,
}: PropsWithChildren<SectionContentListProps>) => {
  const classes = useSectionContentListStyles({ showLineNumber });

  return <ul className={classes.sectionContentList}>{children}</ul>;
};

const useSectionContentStyles = makeStyles(theme => ({
  valuesContainer: {
    fontFamily: 'monospace',
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));

type SectionContentProps = {
  className?: string;
} & (
  | {
      currentTab: string;
      showLineNumber: boolean;
      tabsContent: TabContent;
      emptyDataMessage: string;
      renderItemValue?: (value: string) => React.ReactNode;
    }
  | PropsWithChildren<{}>
);

export const SectionContent = ({
  className,
  ...restProps
}: SectionContentProps) => {
  const classes = useSectionContentStyles();

  const renderContent = () => {
    if ('tabsContent' in restProps) {
      const data = restProps.tabsContent[restProps.currentTab].data;
      return (
        <SectionContentList showLineNumber={restProps.showLineNumber}>
          {data.length ? (
            data.map((value, index) => (
              <SectionContentListItem
                key={value}
                value={value}
                index={index}
                showLineNumber={restProps.showLineNumber}
                renderItemValue={restProps.renderItemValue}
              />
            ))
          ) : (
            <Alert severity="info">{restProps.emptyDataMessage}</Alert>
          )}
        </SectionContentList>
      );
    }

    return restProps.children;
  };

  return (
    <div className={clsx(classes.valuesContainer, className)}>
      {renderContent()}
    </div>
  );
};
