import React from 'react';
import { Dialog } from '@material-ui/core';

import { type ManualAction } from '../../../../../../hooks/useManualActions';
import { type SubscriptionEntity } from '../../../../../../../../types/SubscriptionEntity';

import { ManualActionsDialogTitle } from './ManualActionsDialogTitle';
import { ManualActionsDialogContent } from './ManualActionsDialogContent';

export type ManualActionsDialogProps = {
  open: boolean;
  onClose: () => void;
  subscription: SubscriptionEntity;
  manualActions: ManualAction[];
};

export const ManualActionsDialog = (props: ManualActionsDialogProps) => {
  const { open, onClose, manualActions, subscription } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <ManualActionsDialogTitle
        manualActions={manualActions}
        subscription={subscription}
        onClose={onClose}
      />
      <ManualActionsDialogContent manualActions={manualActions} />
    </Dialog>
  );
};
