import { Entity } from '@backstage/catalog-model';
import { useDeploymentsStore } from '../../../store/deployments-store';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  getComponentOrResourceAppliedStatus,
  parseComponentOrResourceName,
} from '../manifest-utils';
import { ApiAppliedStatus } from '../../../../../../../../../types/applied-status/AppliedStatus';

export const useVaultUrl = (sourceEntity?: Entity) => {
  const drawerCurrentEntity = useDeploymentsStore(state => state.entity);

  let component: Entity | undefined;
  let resource: Entity | undefined;

  if (sourceEntity?.kind === 'Component') {
    resource = drawerCurrentEntity;
    component = sourceEntity;
  } else if (sourceEntity?.kind === 'Resource') {
    resource = sourceEntity;
    component = drawerCurrentEntity;
  }

  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);
  const configApi = useApi(configApiRef);

  if (!component || !subscription || !productVersion || !resource)
    return {
      error: new Error(
        'Could not get Vault URL because either component, subscription, productVersion or resource is missing',
      ),
    };

  const vaultBaseUrl = configApi.getOptionalString('vault.baseUrl');

  if (!vaultBaseUrl)
    return {
      error: new Error(`Vault base URL is not configured`),
    };

  const vaultNamespace = subscription.spec.aap?.vaultNamespace;

  if (!vaultNamespace)
    return {
      error: new Error(
        `Failed to find vault namespace for subscription ${subscription.metadata.name}`,
      ),
    };

  const componentAppliedStatusOrError =
    getComponentOrResourceAppliedStatus<ApiAppliedStatus>(
      component,
      subscription,
      productVersion,
    );

  if ('error' in componentAppliedStatusOrError)
    return componentAppliedStatusOrError;

  const resourceName = parseComponentOrResourceName(resource) ?? '';

  const resourceSecrets =
    componentAppliedStatusOrError.vault?.secrets?.resources?.[resourceName];
  const vaultPath =
    (resourceSecrets as { path: string }).path ||
    (resourceSecrets as { topicPath: string }).topicPath;

  if (!vaultPath)
    return { error: new Error('Failed to find vault path for resource') };

  return {
    data: `${vaultBaseUrl}/${encodeURIComponent(vaultPath)}/details?namespace=${encodeURIComponent(vaultNamespace)}`,
  };
};
