import { Entity } from '@backstage/catalog-model';
import each from 'lodash.foreach';
import { AppliedProductManifest } from '../../../../../../../../types/applied-product-manifest/AppliedProductManifest';
import {
  getAppliedManifest,
  parseComponentOrResourceName,
} from '../common/manifest-utils';
import { useDeploymentsStore } from '../../store/deployments-store';
import { TabContent } from '../common/SectionContent';

/**
 * Find which component is using the topic and return the associated secrets
 * Also return cluster and registry information
 */
const getComponentsUsingTopic = (
  resource: Entity,
  productAppliedManifest: AppliedProductManifest['product'],
) => {
  const result: TabContent<string[] | Record<string, string[]>> = {};

  each(productAppliedManifest.components, componentFamily => {
    each(componentFamily, (component, componentName) => {
      const kafkaTopics = component.secrets?.env?.fromResources?.kafkatopics;

      if (!kafkaTopics) return;

      result.cluster = {
        data: kafkaTopics.cluster.map(
          (secret: { name: string }) => secret.name,
        ),
      };
      result.registry = {
        data: kafkaTopics.schemaRegistry.map(
          (secret: { name: string }) => secret.name,
        ),
      };

      const parsedResourceName = parseComponentOrResourceName(resource);

      result.topic = {
        data: {
          [componentName]: parsedResourceName
            ? kafkaTopics[parsedResourceName]?.map(
                (secret: { name: string }) => secret.name,
              )
            : [],
        },
      };
    });
  });

  return result;
};

export const useKafkaTopicResourceSecrets = ():
  | { error: Error }
  | { data: TabContent<string[] | Record<string, string[]>> } => {
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);

  if (!entity || !subscription || !productVersion)
    return {
      error: new Error(
        'Could not find resource secrets because either resource, subscription or productVersion is missing',
      ),
    };

  const appliedManifest = getAppliedManifest(
    subscription,
    productVersion?.metadata.name,
  );

  if (!appliedManifest)
    return {
      error: new Error(
        `Could not find appliedManifest in subscription ${subscription.metadata.name} for version ${productVersion.metadata.title}`,
      ),
    };

  return {
    data: getComponentsUsingTopic(entity, appliedManifest),
  };
};
