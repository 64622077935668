import { SubscriptionEntity } from '../../../../../../../../types/SubscriptionEntity';
import { Entity } from '@backstage/catalog-model';
import {
  ComponentFamily,
  ResourceFamily,
} from '../../../../../../../../types/applied-product-manifest/AppliedProductManifest';

export const getAppliedManifest = (
  subscription?: SubscriptionEntity,
  productId?: string,
) =>
  subscription?.spec.aap?.products?.[productId ?? '']?.appliedProductManifest
    ?.product;
const getAppliedStatus = (
  subscription?: SubscriptionEntity,
  productId?: string,
) => subscription?.spec.aap?.products?.[productId ?? '']?.appliedStatus;

const componentNameRegex = /^[^-]+-(.*)$/;

const getComponentOrResourceFamily = (entity: Entity) => {
  // Need to lowercase the type for camelcase types like kafkaTopic that should match key kafkatopics in the manifest
  return `${(entity.spec?.type as string)?.toLowerCase()}s` as
    | ComponentFamily
    | ResourceFamily;
};

export const parseComponentOrResourceName = (component: Entity) =>
  component?.metadata.name.match(componentNameRegex)?.[1];

export const getComponentOrResourceAppliedManifest = <T>(
  entity: Entity,
  subscription: SubscriptionEntity,
  productVersion: Entity,
): T | { error: Error } => {
  const appliedManifest = getAppliedManifest(
    subscription,
    productVersion?.metadata.name,
  );

  if (!appliedManifest)
    return {
      error: new Error(
        `Could not find appliedManifest in subscription ${subscription.metadata.name} for version ${productVersion.metadata.title}`,
      ),
    };

  const parsedEntityName = parseComponentOrResourceName(entity);

  if (!parsedEntityName)
    return {
      error: new Error(
        `Failed to parse entity name for ${entity.metadata.title ?? entity.metadata.name}`,
      ),
    };

  const entityFamily = getComponentOrResourceFamily(entity);

  if (entity.kind.toLowerCase() === 'component') {
    const entityManifest = appliedManifest?.components?.[
      entityFamily as ComponentFamily
    ]?.[parsedEntityName ?? ''] as T;

    return (
      entityManifest ?? {
        error: new Error(
          `Component ${entity.metadata.title} has empty manifest`,
        ),
      }
    );
  } else if (entity.kind.toLowerCase() === 'resource') {
    const entityManifest = appliedManifest?.resources?.[
      entityFamily as ResourceFamily
    ]?.[parsedEntityName ?? ''] as T;

    return (
      entityManifest ?? {
        error: new Error(
          `Resource ${entity.metadata.title} has empty manifest`,
        ),
      }
    );
  }

  return {
    error: new Error(
      `Cannot get appliedManifest on unknown entity kind ${entity.kind}`,
    ),
  };
};

export const getComponentOrResourceAppliedStatus = <T>(
  entity: Entity,
  subscription: SubscriptionEntity,
  productVersion: Entity,
): T | { error: Error } => {
  const appliedStatus = getAppliedStatus(
    subscription,
    productVersion?.metadata.name,
  );

  if (!appliedStatus)
    return {
      error: new Error(
        `Could not find appliedStatus in subscription ${subscription.metadata.name} for version ${productVersion.metadata.title}`,
      ),
    };

  const parsedEntityName = parseComponentOrResourceName(entity);

  if (!parsedEntityName)
    return {
      error: new Error(
        `Failed to parse entity name for ${entity.metadata.title ?? entity.metadata.name}`,
      ),
    };

  const entityFamily = getComponentOrResourceFamily(entity);

  if (entity.kind.toLowerCase() === 'component') {
    return (
      (appliedStatus?.components?.[entityFamily as ComponentFamily]?.[
        parsedEntityName ?? ''
      ] as T) ?? {
        error: new Error(`Component ${entity.metadata.title} has empty status`),
      }
    );
  } else if (entity.kind.toLowerCase() === 'resource') {
    return (
      (appliedStatus?.resources?.[entityFamily as ResourceFamily]?.[
        parsedEntityName ?? ''
      ] as T) ?? {
        error: new Error(`Resource ${entity.metadata.title} has empty status`),
      }
    );
  }

  return {
    error: new Error(
      `Cannot get appliedStatus on unknown entity kind ${entity.kind}`,
    ),
  };
};
