import { ControlledComponentAndResourceEntityBreadcrumb } from '../../../../../entity-layout/entity-breadcrumbs/ComponentAndResourceEntityBreadcrumb';
import { makeStyles, Typography } from '@material-ui/core';
import {
  ComponentEntity,
  Entity,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  catalogApiRef,
  getEntityRelations,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useDeploymentsStore } from '../store/deployments-store';
import { EntitySelector } from '../../../../../../common/EntitySelector';

const useDrawerBreadcrumbStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbDivider: {
    display: 'flex',
    margin: `0 ${theme.spacing(1)}px`,
    userSelect: 'none',
  },
  selectValue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const useProductVersionChildren = (entity: Entity) => {
  const [productVersionChildren, setProductVersionChildren] = React.useState<
    Entity[]
  >([]);

  const catalogApi = useApi(catalogApiRef);

  const { entities: dependencyOfRelations } = useRelatedEntities(entity, {
    kind: 'Component',
    type: RELATION_DEPENDENCY_OF,
  });

  const productVersion = dependencyOfRelations?.find(
    ent => ent.spec?.type === 'productVersion',
  ) as Entity;

  const componentDependsOn = useMemo(
    () =>
      getEntityRelations(productVersion, RELATION_DEPENDS_ON, {
        kind: 'Component',
      }),
    [productVersion],
  );
  const resourceDependsOn = useMemo(
    () =>
      getEntityRelations(productVersion, RELATION_DEPENDS_ON, {
        kind: 'Resource',
      }),
    [productVersion],
  );

  useEffect(() => {
    const fetchVersionChildren = async () => {
      const { items: children } = await catalogApi.getEntitiesByRefs({
        entityRefs: [...componentDependsOn, ...resourceDependsOn].map(
          stringifyEntityRef,
        ),
      });

      setProductVersionChildren(
        children.filter((child): child is Entity => Boolean(child)),
      );
    };

    fetchVersionChildren();
  }, [componentDependsOn, resourceDependsOn, catalogApi]);

  return productVersionChildren;
};

interface DrawerBreadcrumbProps {
  entity: ComponentEntity;
}

export const DrawerBreadcrumb = ({ entity }: DrawerBreadcrumbProps) => {
  const initialEntity = useRef(entity);

  const classes = useDrawerBreadcrumbStyles();
  const productVersionChildren = useProductVersionChildren(entity);

  const setDrawerEntity = useDeploymentsStore(state => state.changeEntity);

  const onSelectChange = (selectedEntity: Entity) => {
    setDrawerEntity(selectedEntity as ComponentEntity);
  };

  return (
    <div className={classes.root}>
      <ControlledComponentAndResourceEntityBreadcrumb
        entity={initialEntity.current}
        includeLast={false}
        compact
        isExpandable={false}
        disableNavigation
      />
      <Typography variant="body1" color="secondary">
        <span className={classes.breadcrumbDivider}>/</span>
      </Typography>
      {productVersionChildren.length !== 0 && (
        <EntitySelector
          entities={productVersionChildren}
          value={entity}
          onChange={onSelectChange}
        />
      )}
    </div>
  );
};
