import { SectionContent, SectionContentList } from '../SectionContent';
import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '@backstage/catalog-model';
import { parseComponentOrResourceName } from '../manifest-utils';
import { Skeleton } from '@material-ui/lab';
import { ComponentSecretsContentHeaderProps } from './ComponentSecrets/ComponentSecretsContentHeader';
import { ResourceSecretsContentHeaderProps } from './ResourceSecrets/ResourceSecretsContentHeader';
import { makeStyles } from '@material-ui/core';
import { useDelayedLoading } from '../../../../../../../../../hooks/useDelayedLoading';
import isEmpty from 'lodash.isempty';
import { SectionContentWithHeaderSecretRenderer } from './SectionContentWithHeaderSecretRenderer';
import { ComponentResourcesSecrets } from './ComponentSecrets/useComponentSecrets';

const useSectionContentWithHeaderSkeletonStyles = makeStyles(theme => ({
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonLine: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
}));

const SectionContentWithHeaderSkeleton = () => {
  const classes = useSectionContentWithHeaderSkeletonStyles();

  return (
    <div className={classes.skeletonContainer}>
      {Array(3)
        .fill(null)
        .map(() => (
          <div className={classes.skeletonLine}>
            <Skeleton width={15} height={25} />
            <Skeleton width={400} height={25} />
          </div>
        ))}
    </div>
  );
};

interface SectionContentWithHeaderProps {
  secretData: ComponentResourcesSecrets;
  emptySecretsMessage: string;
  SecretsContentHeaderComponent: React.FC<
    ComponentSecretsContentHeaderProps | ResourceSecretsContentHeaderProps
  >;
}

/**
 * Renders secrets with a selector to change the entity to display secrets for
 * Secrets could just be an array of string or an object with string arrays
 * Object with string arrays is used for kafka topics to have sections of secrets (for cluster, registry, etc)
 */
export const SectionContentWithHeader = ({
  secretData,
  emptySecretsMessage,
  SecretsContentHeaderComponent,
}: SectionContentWithHeaderProps) => {
  const [secretsToDisplay, setSecretsToDisplay] = useState<
    string[] | Record<string, string[]>
  >([]);
  const [selectedResourceType, setSelectedResourceType] = useState<string>('');
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onLoadingChange = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const loading = useDelayedLoading(isLoading);

  // Memoize to avoid triggering useEffect that have a dependency on this function
  const onEntityChange = useCallback(
    (entity: Entity) => {
      const parsedEntityName = parseComponentOrResourceName(entity);

      if (!parsedEntityName) {
        setError(
          new Error(`Failed to parse entity name for ${entity.metadata.title}`),
        );
        return;
      }

      setSelectedResourceType(entity.spec?.type as string);
      setError(undefined);
      setSecretsToDisplay(secretData[parsedEntityName]);
    },
    [secretData],
  );

  const defaultEntityName = useMemo(
    () =>
      Object.keys(secretData).find(
        entityName => !isEmpty(secretData[entityName]),
      ),
    [secretData],
  );

  return (
    <SectionContent>
      <SecretsContentHeaderComponent
        onChange={onEntityChange}
        defaultResourceName={defaultEntityName}
        onLoadingChange={onLoadingChange}
        hasSecrets={!isEmpty(secretsToDisplay)}
      />
      {loading ? (
        <SectionContentWithHeaderSkeleton />
      ) : (
        <SectionContentList showLineNumber>
          <SectionContentWithHeaderSecretRenderer
            secrets={secretsToDisplay}
            error={error}
            resourceType={selectedResourceType}
            emptySecretsMessage={emptySecretsMessage}
          />
        </SectionContentList>
      )}
    </SectionContent>
  );
};
