import { type MetricsMapFunction } from '../useMetrics';
import { createMetrics } from '../../../utils/createMetrics';
import { SubscriptionEntity } from '../../../types/SubscriptionEntity';
import { Entity } from '@backstage/catalog-model';
import { useDeploymentsStore } from '../../../components/catalog/pages/component/product/deployments/store/deployments-store';
import { getComponentOrResourceAppliedManifest } from '../../../components/catalog/pages/component/product/deployments/DeploymentDrawer/common/manifest-utils';
import { ComponentManifest } from '../../../types/applied-product-manifest/AppliedProductManifest';
import { CronjobManifest } from '../../../types/applied-product-manifest/CronjobManifest';
import ScheduleIcon from '@material-ui/icons/Schedule';

const getCronjobSchedule = (
  entity: Entity,
  relatedSubscription?: SubscriptionEntity,
  relatedProductVersion?: Entity,
) => {
  if (!relatedSubscription || !relatedProductVersion)
    return {
      error: new Error(
        `Subscription or ProductVersion not found for component ${entity.metadata.title}`,
      ),
    };

  const appliedManifestOrError =
    getComponentOrResourceAppliedManifest<ComponentManifest>(
      entity,
      relatedSubscription,
      relatedProductVersion,
    );

  if ('error' in appliedManifestOrError) return appliedManifestOrError;

  return (appliedManifestOrError as CronjobManifest).schedule;
};

export const getScheduleMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  const scheduleOrError = getCronjobSchedule(
    entity,
    useDeploymentsStore.getState().subscription,
    useDeploymentsStore.getState().productVersion,
  );

  if (typeof scheduleOrError === 'object')
    return { metrics: [], error: scheduleOrError.error };

  if (!scheduleOrError)
    return {
      metrics: [],
      error: new Error(`Schedule not found for ${entity.metadata.title}`),
    };

  return {
    metrics: [
      createMetrics(scheduleOrError, 'Schedule', { Icon: ScheduleIcon }),
    ],
  };
};
