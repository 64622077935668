import React from 'react';
import { ProductVersionDrawerHeader } from './ProductVersionDrawerHeader';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ProductVersionDrawerBreadcrumb } from './ProductVersionDrawerBreadcrumb';
import { useProductVersionDrawerStore } from './product-version-drawer-store';
import { ProductVersionDrawerContent } from './ProductVersionDrawerContent';

const useProductVersionDrawerStyles = makeStyles(theme => ({
  drawer: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    minWidth: 850,
  },
  headerBox: {
    marginBottom: theme.spacing(1.5),
    gap: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

interface ProductVersionDrawerProps {
  onClose: () => void;
}

export const ProductVersionDrawer = ({
  onClose,
}: ProductVersionDrawerProps) => {
  const productVersion = useProductVersionDrawerStore(
    state => state.selectedProductVersion,
  );

  const classes = useProductVersionDrawerStyles();

  return (
    <Drawer
      anchor="right"
      open={!!productVersion}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
    >
      {productVersion && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.headerBox}
          >
            <ProductVersionDrawerBreadcrumb entity={productVersion} />
            <IconButton
              onClick={onClose}
              data-testid="close-product-version-drawer"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <ProductVersionDrawerHeader entity={productVersion} />
          <ProductVersionDrawerContent entity={productVersion} />
        </>
      )}
    </Drawer>
  );
};
