import { useEffect, useRef } from 'react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { SubscriptionEntity } from '../../../../../../../types/SubscriptionEntity';
import { useSearchParams } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useDeploymentsStore } from '../store/deployments-store';

export const useDeployedComponentOrResourcesFromUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasParsedSearchParams = useRef(false);

  const catalogApi = useApi(catalogApiRef);

  const isOpen = useDeploymentsStore(state => state.open);
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);
  const openDrawer = useDeploymentsStore(state => state.openDrawer);

  useEffect(() => {
    if (isOpen && subscription && entity && productVersion) {
      // when opening the drawer, update the url
      setSearchParams({
        subscription: stringifyEntityRef(subscription),
        entity: stringifyEntityRef(entity),
        productVersion: stringifyEntityRef(productVersion),
      });
    } else {
      // When closing the drawer, remove the url params
      // delete only searchParams concerning the drawer
      setSearchParams(sp => {
        const newSearchParams = new URLSearchParams(sp);
        newSearchParams.delete('entity');
        newSearchParams.delete('productVersion');
        return newSearchParams;
      });
    }
  }, [isOpen, setSearchParams, subscription, entity, productVersion]);

  useEffect(() => {
    const subscriptionRef = searchParams.get('subscription') as string;
    const entityRef = searchParams.get('entity') as string;
    const productVersionRef = searchParams.get('productVersion') as string;

    // at least one is missing in the url, not usable
    if (!subscriptionRef || !entityRef || !productVersionRef) {
      hasParsedSearchParams.current = true;
      return;
    }

    if (!hasParsedSearchParams.current) {
      catalogApi
        .getEntitiesByRefs({
          entityRefs: [subscriptionRef, entityRef, productVersionRef],
        })
        .then(async ({ items }) => {
          const subscriptionEntity = items[0];
          const drawerEntity = items[1];
          const productVersionEntity = items[2];

          if (!drawerEntity || !subscriptionEntity || !productVersionEntity) {
            hasParsedSearchParams.current = true;
            return;
          }

          openDrawer(
            drawerEntity,
            subscriptionEntity as SubscriptionEntity,
            productVersionEntity,
          );
          hasParsedSearchParams.current = true;
        });
    }
  }, [
    catalogApi,
    searchParams,
    openDrawer,
    isOpen,
    entity,
    productVersion,
    subscription,
  ]);
};
