import {
  Box,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { Alert } from '@material-ui/lab';
import {
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  EntityPresentationMetrics,
  type Tag,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { DrawerBreadcrumb } from './DrawerBreadcrumb';
import { useDeploymentsStore } from '../store/deployments-store';
import CloseIcon from '@material-ui/icons/Close';
import { StatusBadge } from '../StatusBadge';
import { ErrorIcon } from 'backstage-plugin-icons-react';
import { useMetrics } from '../../../../../../../hooks/metrics/useMetrics';
import { ComponentEntity, Entity } from '@backstage/catalog-model';
import { DeploymentDrawerContent } from './DeploymentDrawerContent';
import { useDeployedComponentOrResourcesFromUrl } from './useDeployedComponentOrResourcesFromUrl';

const useDeploymentDrawerStyles = makeStyles(theme => ({
  drawer: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    minWidth: 850,
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerBox: {
    marginBottom: theme.spacing(1.5),
    gap: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  entityPresentation: {
    flexGrow: 'initial',
  },
}));

export const DeploymentDrawer = () => {
  const classes = useDeploymentDrawerStyles();

  // Will open the drawer if the URL contains the entity, subscription and productVersion
  useDeployedComponentOrResourcesFromUrl();

  const isOpen = useDeploymentsStore(state => state.open);
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);

  const entityMetrics = useDeploymentsStore(state => state.entityMetrics);
  const closeDrawer = useDeploymentsStore(state => state.closeDrawer);
  const statuses = useDeploymentsStore(state => state.statuses);

  const displayedStatuses = useMemo(() => {
    if (
      subscription &&
      statuses[subscription.metadata.name]?.health === 'Stand by'
    ) {
      return statuses[subscription.metadata.name];
    }
    return entity && statuses[entity.metadata.name];
  }, [subscription, statuses, entity]);

  const project =
    subscription?.metadata.annotations?.['aap.adeo.cloud/project'];

  const projectTag: Tag | undefined = project
    ? ({
        label: (
          <span>
            Deployed in <strong>{project}</strong>
          </span>
        ),
        color: 'primary',
        variant: 'outlined',
      } satisfies Tag)
    : undefined;

  const statusTags = displayedStatuses
    ? [
        {
          label: (
            <>
              <StatusBadge status={displayedStatuses?.health} />
              <span>{displayedStatuses?.health}</span>
            </>
          ),
          variant: 'outlined',
        },
        {
          label: (
            <>
              <StatusBadge status={displayedStatuses?.sync} />
              <span>{displayedStatuses?.sync}</span>
            </>
          ),
          variant: 'outlined',
        },
      ]
    : [
        {
          label: 'No health status',
          variant: 'outlined',
          icon: ErrorIcon,
        },
        {
          label: 'No sync status',
          variant: 'outlined',
          icon: ErrorIcon,
        },
      ];

  const tags: Tag[] = [...statusTags, projectTag].filter(
    (tag): tag is Tag => !!tag,
  );

  const {
    loading: loadingMetrics,
    metrics,
    error: metricsError,
  } = useMetrics(entity as Entity, entityMetrics);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      classes={{ paper: classes.drawer }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerBox}
      >
        {entity && <DrawerBreadcrumb entity={entity as ComponentEntity} />}
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <EntityPresentation size="medium" className={classes.entityPresentation}>
        <EntityPresentationHeader
          title={entity?.metadata.title ?? ''}
          type={entity?.spec?.type as string}
          tags={tags}
        />
        <EntityPresentationDescription
          description={entity?.metadata.description ?? ''}
        />
        {metricsError ? (
          <Alert severity="error" className={classes.errorContainer}>
            {metricsError.message}
          </Alert>
        ) : (
          entityMetrics && (
            <EntityPresentationMetrics
              metrics={metrics}
              loading={loadingMetrics}
            />
          )
        )}
        <Divider />
      </EntityPresentation>
      <DeploymentDrawerContent />
    </Drawer>
  );
};
