import React, { useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';

import {
  usePutManualActionMutation,
  type ManualAction,
} from '../../../../../../hooks/useManualActions';

import { SaveIcon } from 'backstage-plugin-icons-react';
import { ManualActionsResolvedFields } from './ManualActionsResolvedFields';
import { ManualActionsNonResolvedFields } from './ManualActionsNonResolvedFields';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEntity } from '@backstage/plugin-catalog-react';

const stringToNumber = (val: unknown) => {
  if (typeof val !== 'string' || val === '') return undefined;
  return Number(val);
};

const stringToBoolean = (val: unknown) => {
  if (val === '') return undefined;
  if (val === 'true') return true;
  if (val === 'false') return false;
  return val;
};

const createFieldSchema = (field: { key: string; type: string }) => {
  if (field.type === 'string') {
    return z
      .string({
        required_error: `${field.key} is required`,
        invalid_type_error: `${field.key} must be a string`,
      })
      .min(1, {
        message: `${field.key} must not be empty`,
      });
  }
  if (field.type === 'number') {
    return z.preprocess(
      stringToNumber,
      z.number({
        required_error: `${field.key} is required`,
        invalid_type_error: `${field.key} must be a number`,
      }),
    );
  }
  if (field.type === 'boolean') {
    return z.preprocess(
      stringToBoolean,
      z.boolean({
        required_error: `${field.key} is required`,
        invalid_type_error: `${field.key} must be a boolean`,
      }),
    );
  }
  return z.any();
};

const createOptionalFieldSchema = (field: { key: string; type: string }) => {
  if (field.type === 'string') {
    return z.string().optional();
  }
  if (field.type === 'number') {
    return z.preprocess(stringToNumber, z.number().optional());
  }
  if (field.type === 'boolean') {
    return z.preprocess(stringToBoolean, z.boolean().optional());
  }
  return z.any().optional();
};

type ManualActionsDialogItemProps = {
  manualAction: ManualAction;
  index: number;
  isLast: boolean;
};

type IManualActionForm = {
  [key: string]: string;
};

const useManualActionsDialogItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  isLast: {
    borderBottom: 'none',
  },

  manualActionHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flex: 1,
  },

  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },

  index: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  manualActionTitle: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '18px',
  },
}));

export const ManualActionsDialogItem = (
  props: ManualActionsDialogItemProps,
) => {
  const { manualAction, index, isLast } = props;
  const classes = useManualActionsDialogItemStyles();
  const [editedFields, setEditedFields] = useState<Record<string, boolean>>({});
  const { entity } = useEntity();
  const putManualActionMutation = usePutManualActionMutation({
    entity,
    manualAction,
  });

  const schema = useMemo(() => {
    if (manualAction.status === 'INITIALIZED') {
      const fields = manualAction.stepTemplate.fields.reduce((acc, field) => {
        return {
          ...acc,
          [field.key]: createFieldSchema(field),
        };
      }, {});

      return z.object(fields);
    }

    const fields = manualAction.stepTemplate.fields.reduce((acc, field) => {
      return {
        ...acc,
        [field.key]: createOptionalFieldSchema(field),
      };
    }, {});

    return z.object(fields);
  }, [manualAction]);

  const methods = useForm<IManualActionForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...manualAction.stepTemplate.fields.reduce(
        (acc, field) => ({
          ...acc,
          [field.key]: '',
        }),
        {},
      ),
    },
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = methods;

  const mainClasses = isLast
    ? `${classes.root} ${classes.isLast}`
    : classes.root;

  const onSubmit = (data: IManualActionForm) => {
    if (manualAction.status === 'SUCCESS') {
      let hasErrors = false;
      Object.keys(data).forEach(key => {
        if (editedFields[key] && data[key] === '') {
          hasErrors = true;
          setError(key, {
            type: 'manual',
            message: `${key} must not be empty`,
          });
        }
      });
      if (hasErrors) return;
    }

    if (Object.values(data).every(value => !value)) {
      setError('global', {
        type: 'manual',
        message: 'At least one field must be provided',
      });
      return;
    }

    // Remove empty strings from the data
    const dataWithoutEmptyString = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (value !== '') return { ...acc, [key]: value };
        return acc;
      },
      {},
    );

    putManualActionMutation.mutate({ input: dataWithoutEmptyString });
  };

  const submitIsDisabled = !isDirty || isSubmitting || !isValid;

  return (
    <FormProvider {...methods}>
      <form className={mainClasses} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.manualActionHeader}>
          <Typography variant="body2" className={classes.index}>
            {index + 1}
          </Typography>

          <Typography className={classes.manualActionTitle}>
            {manualAction.stepTemplate.title} - {manualAction.name}
          </Typography>
        </div>

        <Typography variant="body2">
          {manualAction.stepTemplate.description}
        </Typography>

        {manualAction.status === 'SUCCESS' ? (
          <ManualActionsResolvedFields
            fields={manualAction.stepTemplate.fields}
            control={control}
            formErrors={errors}
            editedFields={editedFields}
            setEditedFields={setEditedFields}
            disabled={putManualActionMutation.isPending}
          />
        ) : (
          <ManualActionsNonResolvedFields
            fields={manualAction.stepTemplate.fields}
            control={control}
            formErrors={errors}
            disabled={putManualActionMutation.isPending}
          />
        )}

        {errors.global && (
          <Typography color="error" variant="body2">
            At least one field must be provided
          </Typography>
        )}

        <div className={classes.saveButtonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            startIcon={
              putManualActionMutation.isPending ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <SaveIcon />
              )
            }
            disabled={submitIsDisabled || putManualActionMutation.isPending}
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
