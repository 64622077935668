import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { BlockIcon } from 'backstage-plugin-icons-react';
import { ManualAction } from '../../../../../../hooks/useManualActions';

const useDeploymentAccordionTitleStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    overflow: 'hidden',
  },
  textContainer: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
  },
  title: {
    fontWeight: 700,
  },
}));

interface DeploymentAccordionTitleProps {
  title?: string;
  subtitle?: string;
  nonResolvedManualActions?: ManualAction[];
}

export const DeploymentAccordionTitle = ({
  title,
  subtitle,
  nonResolvedManualActions,
}: DeploymentAccordionTitleProps) => {
  const classes = useDeploymentAccordionTitleStyles();

  return (
    <div className={classes.root}>
      {nonResolvedManualActions && nonResolvedManualActions.length > 0 && (
        <BlockIcon color="error" data-testid="manual-actions-icon" />
      )}
      <div className={classes.textContainer}>
        <Typography component="span" variant="body2" className={classes.title}>
          {title}
        </Typography>
        <br />
        <Typography
          component="span"
          variant="body2"
          className={classes.secondaryText}
        >
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};
